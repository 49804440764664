//=require ../../node_modules/bootstrap/dist/js/bootstrap.min.js
//=require ../../node_modules/swiper/dist/js/swiper.min.js
//=require ../../node_modules/lightbox2/dist/js/lightbox.min.js
//=require ../../node_modules/waypoints/lib/jquery.waypoints.min.js
//=require ../../node_modules/gsap/src/minified/TweenMax.min.js
//=require ../../node_modules/masonry-layout/dist/masonry.pkgd.min.js


/* jshint -W030 */
!function ($, window) {
  "use strict";

  // Vars
  var $doc = $(document), $win = $(window), SITE = SITE || {};

  SITE = {
    // Init
    init: function () {
      var obj, self = this;
      for (obj in self) if (self.hasOwnProperty(obj)) {
        var _method = self[obj];
        void 0 !== _method.selector && void 0 !== _method.init && $(_method.selector).length > 0 && _method.init();
      }
    },

    //Multi Level Menu
    MultiLevelMenu: {
      selector: "#nav",
      init: function () {
        var base = this, container = $(base.selector);

        var el = $("#nav ul li a");
        var elActive = $("#nav > ul").find("a.active");

        elActive.parent("li").addClass("active");
        elActive.parent("li").parents("li").addClass("active-trail");

        if ($("#nav ul ul").length) {
          $("#nav > ul").addClass("sublvl");
        }

        el.each(function () {
          if ($(this).next("ul").length) {
            $(this).after('<span class="expand"></span>');
          }
        });

        $("#nav ul li .expand").on("click", function () {
          $(this).parent("li").toggleClass('open');
        });
        $("#nav ul li.fb-link a").wrapInner("<span></span>");
      }
    },

    //Data to BG
    DataToBg: {
      selector: "*[data-bg]",
      init: function () {
        var base = this, container = $(base.selector);

        container.each(function () {
          var bg = $(this).data('bg');
          if (bg.substr(0, 1) === '#') {
            $(this).css({
              'background-color': bg
            });
          } else {
            $(this).css({
              'background-image': 'url(' + bg + ')'
            });
          }
        });
      }
    },

    // Fixed navigation
    NavScrolled: {
      selector: ".main-header",
      init: function () {
        var base = this, container = $(base.selector);
        if ($(document).scrollTop() > 60) {
          container.addClass("scrolled");
        } else {
          container.removeClass("scrolled");
        }
      }
    },
    // Fixed navigation
    masonryManga: {
      selector: ".node-type-promotion-manga .grid",
      start: function () {
        var base = this, container = $(base.selector);

        container.masonry({
          columnWidth: '.grid-sizer',
          itemSelector: '.grid-image-item',
          gutter: '.gutter-sizer',
          percentPosition: true
        });
      }
    },
    // Force accept conditions
    ForceConditions: {
      selector: ".legal-info input[type='checkbox']",
      init: function () {
        var base = this, container = $(base.selector);
        if ($(".legal-info input[type='checkbox']:checked").length < 2) {
          $('#edit-submit').attr('disabled', '');
        }
        $(document).on('click', base.selector, function (e) {
          if ($(".legal-info input[type='checkbox']:checked").length == 2) {
            $('#edit-submit').removeAttr('disabled');
            $('.form-accept').hide();
          } else {
            $('#edit-submit').attr('disabled', '');
            $('.form-accept').show();
          }
        });
      }
    },

    // Variation change
    /*ProductVariationChange: {
      selector: "#ciaco-products-form select",
      init: function () {
        var base = this, container = $(base.selector);
        $(document).on('change', base.selector, function (e) {
          var selectedVal = $(this).val();
          $(this).parents('.var-item').find('a.plus').attr('href', function (i, a) {
            return a.replace(/([?&]vid)=([^#&]*)/g, '$1=' + selectedVal);
          });
        });
      }
    },*/
    plusMinUrl: {
      selector: ".node-type-product form .var-item",
      init: function () {
        var base = this, container = $(base.selector);
        $(document).ready(function (e) {
          var path = window.location.href;
          var idPath = path.substring(path.lastIndexOf('v=') + 2);
          console.log(idPath);
          var plusToChange = $("a.plus").attr('href');
          var minToChange = $("a.min").attr('href');
          var plusNewUrl = plusToChange.substring(0, plusToChange.indexOf('v=')) + "v=" + idPath;
          var minNewUrl = minToChange.substring(0, minToChange.indexOf('v=')) + "v=" + idPath;
          if (window.location.href.indexOf("v=") > -1) {
            $('a.plus').attr("href", plusNewUrl);
            $('a.min').attr("href", minNewUrl);
          }

        });

        $(document).on('click', base.selector, function (e) {
          var idVar = $(this).attr("data-nid");
          var plusToChange = $("a.plus").attr('href');
          var minToChange = $("a.min").attr('href');
          var plusNewUrl = plusToChange.substring(0, plusToChange.indexOf('v=')) + "v=" + idVar;
          var minNewUrl = minToChange.substring(0, minToChange.indexOf('v=')) + "v=" + idVar;
          console.log(idVar + "," + plusToChange + "," + minToChange + "," + plusNewUrl + "," + minNewUrl + ",");

          $('a.plus').attr("href", plusNewUrl);
          $('a.min').attr("href", minNewUrl);

        });
      }
    },
    // Ajaxify
    ListAjax: {
      selector: ".liste-bloc .ajaxify",
      init: function () {
        var base = this, container = $(base.selector), wrapper = $('.liste-bloc');
        $(document).on('click', base.selector, function (e) {
          e.preventDefault();

          // Ajax call
          $.ajax($(this).attr("href"))
            .done(function (data) {
              wrapper.html($(data).find('.liste-bloc').html());
              $(".table-responsive-wrapper").addClass("active");
            })
            .fail(function () {
              console.log('Ajax call failed');
            });
        });
        return false;
      }
    },

    // var open/close
    /*varOpenClose: {
      selector: ".addvar-plus",
      init: function () {
        var base = this, container = $(base.selector);
        container.bind('touchstart click', function (e) {
          e.preventDefault();
          var item = $(this).attr("data-product");
          $(".variation").not($(".variation[data-product=" + item + "]")).removeClass("active");
          $(".variation[data-product=" + item + "]").toggleClass("active");
          $(this).toggleClass("active");
        });
      }
    },*/

    // Fixed navigation
    TableResponsive: {
      selector: ".table-responsive-wrapper",
      init: function () {
        var base = this, container = $(base.selector);
        container.bind('touchstart click', function () {
          $(this).addClass('active');
        });
      }
    },

    // Burger menu
    BurgerMenu: {
      selector: ".trigger-menu",
      init: function () {
        var base = this, container = $(base.selector);
        $(document).on('click', base.selector, function (e) {
          e.preventDefault();
          $(this).toggleClass('open');
          $('body').toggleClass('open-menu');
        });
      }
    },

    // product gallery
    productGallery: {
      selector: ".product-images",
      init: function () {
        var base = this, container = $(base.selector);
        var firstPic = $('.product-thumbs .thumb:first-child').attr("data-bg");
        $(".product-big img").attr('src', firstPic);
        $('.product-big iframe').addClass('hidden');

        $(".product-thumbs .thumb").on('click mouseover', function () {
          var changePic = $(this).attr('data-bg');
          $('.product-big iframe').addClass("hidden");
          $('.product-big img').removeClass('hidden').attr("src", changePic);
        });

        $(".product-thumbs .video").on('click mouseover', function () {
          var videoId = $(this).attr('data-id');
          $('.product-big img').addClass('hidden');
          $('.product-big iframe').removeClass('hidden');
        });

        $("#ciaco-products-node-form input[type='radio']").each(function () {
          if ($(this).is(':checked')) {
            $(this).next("label").addClass('active');
          }
        });
        $("#ciaco-products-node-form input[type='radio']").change(function () {
          $("#ciaco-products-node-form label").removeClass('active');
          $('.thumb').removeClass('active');
          $('.product-big iframe').addClass('hidden');
          $('.product-big img').removeClass('hidden');

          if ($(this).is(':checked')) {

            var clickTitle = $(this).next('label').find(".var-item").attr('title');
            var thumbId = $(this).next('label').find(".var-item").attr('data-nid');
            var thumbPic = $(".thumb[data-nid='" + thumbId + "']").attr('data-bg');

            $(this).next("label").addClass('active');
            $(".product-selected").html(clickTitle);
            $(".product-big img").attr('src', thumbPic);
            $(".thumb[data-nid='" + thumbId + "']").addClass('active');

          }
        });
        $("#ciaco-products-node-form label").mouseover(function () {
          $('.thumb').removeClass('active');
          $('.product-big iframe').addClass('hidden');
          $('.product-big img').removeClass('hidden');

          var clickTitle = $(this).find(".var-item").attr('title');
          var thumbId = $(this).find(".var-item").attr('data-nid');
          var thumbPic = $(".thumb[data-nid='" + thumbId + "']").attr('data-bg');

          $(".product-selected").html(clickTitle);
          $(".product-big img").attr('src', thumbPic);
          $(".thumb[data-nid='" + thumbId + "']").addClass('active');
        });

        $("#ciaco-products-node-form label").mouseout(function () {
          $('.thumb').removeClass('active');
          $('.product-big iframe').addClass('hidden');
          $('.product-big img').removeClass('hidden');

          var clickTitle = $("label.active").find(".var-item").attr('title');
          var thumbId = $("label.active").find(".var-item").attr('data-nid');
          var thumbPic = $(".thumb[data-nid='" + thumbId + "']").attr('data-bg');

          $(".product-selected").html(clickTitle);
          $(".product-big img").attr('src', thumbPic);
          $(".thumb[data-nid='" + thumbId + "']").addClass('active');
        });

        $("#ciaco-products-node-form label").each(function () {
          if ($(this).hasClass('active')) {
            var varTitle = $(this).find(".var-item").attr('title');
            $(".product-selected").html(varTitle);
          }
        });


      }
    },

    // on scroll animation
    OsAnimation: {
      selector: "body",
      init: function () {
        function onScrollInit(items, trigger) {
          items.each(function () {
            var osElement = $(this),
              osAnimationClass = osElement.attr('data-os-animation'),
              osAnimationDelay = osElement.attr('data-os-animation-delay');

            osElement.css({
              '-webkit-animation-delay': osAnimationDelay,
              '-moz-animation-delay': osAnimationDelay,
              'animation-delay': osAnimationDelay
            });

            var osTrigger = (trigger) ? trigger : osElement;

            osTrigger.waypoint(function () {
              osElement.addClass('animated').addClass(osAnimationClass);
            }, {
              triggerOnce: true,
              offset: '90%'
            });
          });
        }

        onScrollInit($('.os-animation'));
        $('.staggered-animation-container').each(function () {
          onScrollInit($(this).find('.staggered-animation'), $(this));
        });
      }
    },

    // cart back link
    cartBack: {
      selector: ".back-cart, .back-js",
      init: function () {
        var base = this, container = $(base.selector);
        container.click(function (e) {
          e.preventDefault();
          window.history.back();
        });
      }
    },
    // Swiper gallery
    ContentGallery: {
      selector: ".swiper-galerie",
      init: function () {
        var base = this, container = $(base.selector);
        var mySwiper = new Swiper(base.selector, {
          slidesPerView: 1,
          spaceBetween: 10,
          speed: 2000,
          autoplay: {
            delay: 3500,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        });
      }
    },

    // Swiper modale
    ModaleSwiper: {
      selector: ".swiper-modal",
      init: function () {
        var base = this, container = $(base.selector);
        var popupGallery = new Swiper(base.selector, {
          slidesPerView: 1,
          spaceBetween: 10,
          speed: 1000,
          watchOverflow: true,
          navigation: {
            nextEl: '.swiper-modal .swiper-button-next',
            prevEl: '.swiper-modal .swiper-button-prev',
          }
        });
      }
    },
    // Swiper modale
    ModaleProducts: {
      selector: ".outlet-item",
      init: function () {
        var base = this, container = $(base.selector);
        container.find('.modal').on('shown.bs.modal', function (e) {
          console.log($(this));
          SITE.ModaleSwiper.init();
        });
      }
    },
    // Swiper gallery
    swiperOtherPromo: {
      selector: ".swiper-other-promo",
      init: function () {
        var base = this, container = $(base.selector);
        var mySwiper = new Swiper(base.selector, {
          slidesPerView: 2,
          spaceBetween: 20,
          speed: 2000,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            640: {
              slidesPerView: 1,
              spaceBetween: 10,
            }
          }
        });
      }
    },
    // Swiper gallery
    swiperRelated: {
      selector: ".swiper-related",
      init: function () {
        var base = this, container = $(base.selector);
        var mySwiper = new Swiper(base.selector, {
          slidesPerView: 2,
          spaceBetween: 20,
          speed: 2000,
          watchOverflow: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          breakpoints: {
            640: {
              slidesPerView: 1,
              spaceBetween: 10,
            }
          }
        });
      }
    },

    // Swiper actu home
    NewsSwiper: {
      selector: ".swiper-portal-news",
      init: function () {
        var base = this, container = $(base.selector);
        var portalSwiper = new Swiper(base.selector, {
          slidesPerView: 1,
          spaceBetween: 10,
          speed: 1200,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          loop: true
        });
      }
    },

    // Swiper products home
    ProductsSwiper: {
      selector: ".swiper-products",
      init: function () {
        var base = this, container = $(base.selector);
        var productsSwiper = new Swiper(base.selector, {
          slidesPerView: 'auto',
          spaceBetween: 20,
          speed: 1200,
          autoplay: {
            delay: 2500,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: '#products .swiper-button-next',
            prevEl: '#products .swiper-button-prev',
          }
        });
      }
    },
    // Swiper products home
    RealisationsSwiper: {
      selector: ".swiper-realisations",
      init: function () {
        var base = this, container = $(base.selector);
        var productsSwiper = new Swiper(base.selector, {
          slidesPerView: 1,
          spaceBetween: 0,
          speed: 1200,
          /*autoplay: {
            delay: 2500,
            disableOnInteraction: false,
          },*/
          navigation: {
            nextEl: '#realisations .swiper-button-next',
            prevEl: '#realisations .swiper-button-prev',
          }
        });
      }
    },
    // parallax papeterie
    parallaxPapeterie: {
      selector: "body",
      init: function () {
        var base = this, container = $(base.selector);
        $.fn.parallax = function (resistance, mouse) {
          var $el = $(this);
          TweenLite.to($el, 0.2,
            {
              x: -((mouse.clientX - (window.innerWidth / 2)) / resistance),
              y: -((mouse.clientY - (window.innerHeight / 2)) / resistance)
            });

        };
        $(document).mousemove(function (e) {
          $('.cahier').parallax(-40, e);
          $('.stabilo').parallax(-30, e);
          $('.taille').parallax(-25, e);
          $('.crayons').parallax(-35, e);
          $('.cartable').parallax(-20, e);
          $('.draws').parallax(-40, e);
          $('.plane').parallax(-20, e);
          $('.book').parallax(-15, e);
        });

      }
    },
    // Close messages
    MessagesClose: {
      selector: ".messages",
      init: function () {
        var base = this, container = $(base.selector);
        container.prepend('<a href="#" class="close">×</a>');

        $(document).on("click", ".messages .close", function (e) {
          e.preventDefault();
          $(this).parents(".messages").slideUp(200);
        });
      }
    },
    // smooth anchors
    smoothAnchor: {
      selector: "body",
      init: function () {
        if (window.location.hash) {
          window.scrollTo(0, 0);
          var anchor = window.location.hash;
          console.log($(anchor).offset().top);

          $('html, body').animate({
            scrollTop: ($(anchor).offset().top) - 200
          }, 500);
        }
      }
    },
    smoothClickAnchor: {
      selector: "a.anchor-link",
      init: function () {
        $(document).on('click', 'a.anchor-link[href^="#"]', function (event) {
          if (!$(this).hasClass("trigger-menu")) {
            event.preventDefault();

            $('html, body').animate({
              scrollTop: ($($.attr(this, 'href')).offset().top) - 200
            }, 500);
          }
        });
      }
    },
    // back links
    backLinks: {
      selector: ".back-link",
      init: function () {
        var base = this, container = $(base.selector);
        container.click(function (e) {
          e.preventDefault();
          history.back(1);
        });
      }
    },
    // Lang menu
    LangMenu: {
      selector: "ul.language-switcher-locale-url",
      init: function () {
        var base = this, container = $(base.selector), activeLang = container.find('li.active a').text();

        // create span with active lang
        container.parent().prepend('<span class="label">' + activeLang + '<i class="fa fa-angle-down" aria-hidden="true"></i></span>');
        // hide  active lang in ul
        container.find('li.active').remove();

        //show ul
        container.parent().find('.label').on('click', function (e) {
          e.stopPropagation();
          container.slideToggle(300);
          $(this).find('.fa').toggleClass('fa-caret-down');
          $(this).find('.fa').toggleClass('fa-caret-up');
        });

        //hide ul
        $(document).on('click', function (e) {
          var $clicked = $(e.target);
          if (!$clicked.parents().hasClass("lang")) {
            container.slideUp(300);
            container.parent().find('.label').find('.fa').removeClass('fa-caret-up');
            container.parent().find('.label').find('.fa').addClass('fa-caret-down');
          }
        });

        $(document).scroll(function () {
          if ($(document).scrollTop() >= 50) {
            container.slideUp(300);
            container.parent().find('.label').find('.fa').removeClass('fa-caret-up');
            container.parent().find('.label').find('.fa').addClass('fa-caret-down');
          }
        });
      }
    }

    // Resize
  }, $win.resize(function () {

    // Scroll
  }), $win.scroll(function () {
    SITE.NavScrolled.init();

    // Ready
  }), $win.load(function () {
    SITE.masonryManga.start();

    // Ready
  }), $doc.ready(function () {
    SITE.init();
  });
}(jQuery, this);
